body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif, 'AllertaStencil';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  src: local('AllertaStencil'), url('./fonts/AllertaStencil-Regular.ttf'), format('truetype');
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.nav-link {
  color: #ffffff;
  font-size: 1.1em;
  margin-bottom: 20px;

}

.nav-link:hover {
  color: #4285f4;
  background-color: #ffffff;
  text-decoration: underline;
}

.nav {
  background-color:#343a40;
}

.navbar {
  padding: 15px 10px;
  background: #fff;
  border: none;
  border-radius: 0;
  margin-bottom: 40px;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
}

.contentheadercss {

  color: #006bd6;
  margin: 4px;
  font-size: 1.4em;
  text-align: right;
  border-radius: .25rem;
  font-family: 'Allerta Stencil';
  font-size: 3vw;
}



.mainContentCSS {
  width: 100%;
  padding: 20px;
  min-height: 100vh;
  transition: all 0.3s;
  background: #f4f6f9
}


/*SIDEBAR*/

.sidebar {
  min-width: 250px;
  max-width: 250px;
  background: #343a40;
  color: #fff;
  transition: all 0.3s;
  padding-left: .5rem;
  padding-right: .5rem;    
}

.sidebar.active {
  min-width: 80px;
  max-width: 80px;
  text-align: center;
  
}

.sidebar.active .sidebar-header span,
.sidebar.active .CTAs {
  display: none;
}

.sidebar.active .sidebar-header strong {
  display: block;
}

.sidebar ul li a {
  text-align: left;
}

.sidebar.active ul li a {
  /*padding: 20px 10px;*/
  text-align: center;
  font-size: 1em;
}

.sidebar.active ul li span {
  display: none;
}

.sidebar.active ul li a i {
  margin-right: 0;
  display: block;
  font-size: 1.8em;
  margin-bottom: 5px;
}

.sidebar.active ul ul a {
  padding: 10px !important;
}

.sidebar.active .dropdown-toggle::after {
  top: auto;
  bottom: 10px;
  right: 50%;
  -webkit-transform: translateX(50%);
  -ms-transform: translateX(50%);
  transform: translateX(50%);
}

.sidebar .sidebar-header {
  padding: 10px;
  background: #343a40;
  border-bottom: 1px solid #4b545c;
}

.sidebar .sidebar-content {
  margin-top: 20px;
  padding: 2px;
  background: #343a40;
  border-bottom: 1px solid #4b545c;
}

.sidebar .sidebar-header strong {
  display: none;
  font-size: 1.8em;
}

.sidebar ul.components {
  padding: 20px 0;
  border-bottom: 1px solid #4b545c;
}

.sidebar ul li a {
  padding: 10px;
  font-size: 1.1em;
  display: block;
}

.sidebar ul li a:hover {
  color: #4285f4;
  background: #fff;
  border-radius: .25rem;
}

.sidebar ul li a i {
  margin-right: 10px;
}

.sidebar ul li.active>a,
a[aria-expanded="true"] {
  color: #fff;
  background: #4285f4;
  border-radius: .25rem;
}

a[data-toggle="collapse"] {
  position: relative;
}

.dropdown-toggle::after {
  display: block;
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
}

ul ul a {
  font-size: 0.9em !important;
  padding-left: 30px !important;
  background: #4285f4;
}

ul.CTAs {
  padding: 20px;
}

ul {
  margin-left: 0;
}

ul.CTAs a {
  text-align: center;
  font-size: 0.9em !important;
  display: block;
  border-radius: 5px;
  margin-bottom: 5px;
}

a.download {
  background: #fff;
  color: #4285f4;
}

a.article,
a.article:hover {
  background: #4285f4 !important;
  color: #fff !important;
}

/*END SIDEBAR*/